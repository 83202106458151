<template>
    <v-content>
        <v-breadcrumbs :items="items" large>
            <template v-slot:item="{ item }">
                <v-breadcrumbs-item :to="item.to"
                                    :disabled="item.disabled"
                                    :exact="item.exact">
                    {{ $t(item.text) }}
                </v-breadcrumbs-item>
            </template>
        </v-breadcrumbs>
        <v-snackbar v-model="snackbar" color="success" :timeout="callbackResponse.timeout" top>
            {{callbackResponse.message}}
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>

        <v-tabs>
            <v-tab>
                {{$t('homestay') +" "+$t('house')+" "+$t('settings')}}
            </v-tab>
            <!--<v-tab>
                HomeStay Room Category/Type Setting
            </v-tab>-->
            <v-tab>
                {{$t('package')+" "+$t('settings')}}
            </v-tab>
            <v-tab>
                {{$t('homestay') +" "+$t('service')+" "+$t('settings')}}
            </v-tab>

            <v-tab>
                {{$t('homestay') +" "+$t('special')+" "+$t('dish')}}
            </v-tab>

            <!--<v-tab>
                HomeStay Room Setting
            </v-tab>-->

            <v-tab-item>
                <v-card flat>
                    <v-card-text>
                        <v-expansion-panels v-model="panel" multiple>
                            <HouseList @formResponseRoom="onResponseRoom"
                                       @formResponseRoomDelete="onResponseDelete" />
                        </v-expansion-panels>
                    </v-card-text>
                </v-card>
            </v-tab-item>

            <!--<v-tab-item>
                <v-card flat>
                    <v-card-text>
                        <v-expansion-panels v-model="panel" multiple>
                            <CategoryList @formResponseRoom="onResponseRoom"
                                          @formResponseRoomDelete="onResponseDelete" />
                        </v-expansion-panels>
                    </v-card-text>
                </v-card>
            </v-tab-item>-->
            <v-tab-item>
                <v-card flat>
                    <v-card-text>
                        <v-expansion-panels v-model="panel" multiple>
                            <PackageList @formResponseRoom="onResponseRoom" @formResponseRoomDelete="onResponseDelete" />
                        </v-expansion-panels>
                    </v-card-text>
                </v-card>
            </v-tab-item>

            <v-tab-item>
                <v-card flat>
                    <v-card-text>
                        <v-expansion-panels v-model="panel" multiple>
                            <ServiceList @formResponseRoom="onResponseRoom" @formResponseRoomDelete="onResponseDelete" />
                        </v-expansion-panels>
                    </v-card-text>
                </v-card>
            </v-tab-item>

            <v-tab-item>
                <v-card flat>
                    <v-card-text>
                        <v-expansion-panels v-model="panel" multiple>
                            <SpecialDishList @formResponseRoom="onResponseRoom" @formResponseRoomDelete="onResponseDelete" />
                        </v-expansion-panels>
                    </v-card-text>
                </v-card>
            </v-tab-item>

            <!--<v-tab-item>
                <v-card flat>
                    <v-card-text>
                        <v-expansion-panels v-model="panel" multiple>
                            <FareList @formResponseFare="onResponseFare" @formResponseFareDelete="onResponseDelete" />
                            <RoomList @formResponseRoom="onResponseRoom" @formResponseRoomDelete="onResponseDelete" />
                        </v-expansion-panels>
                    </v-card-text>
                </v-card>
            </v-tab-item>-->
        </v-tabs>
    </v-content>
</template>

<script>
    //import CategoryList from "../HotelCategory/ListCategory";
    //import FareList from "./HotelRoomSetting/Fare/ListFare";
    //import RoomList from "./HotelRoomSetting/Room/ListRoom";
    import HouseList from "../HomestayHouse/HomestayHouseList";
    import SpecialDishList from "./SpecialDish/SpecialDishList";
    import PackageList from "./Package/List";
    import ServiceList from "./Service/ListService";
    import { parseJwt } from "@/mixins/parseJwt";

    export default {
        name: "List",
        mixins: [parseJwt],
        watch: {
            dialog(val) {
                val || this.close();
            },
            success: {
                handler: function (val, oldVal) {
                    console.log(val, oldVal);
                    this.loadItems();
                },
                deep: true
            }
        },
        components: {
            //FareList,
            //RoomList,
            HouseList,
            PackageList,
            SpecialDishList,
            ServiceList
            //CategoryList
        },
        data() {
            return {
                callbackResponse: {
                    timeout: 6000
                },
                snackbar: false,
                success: false,
                dialogCreateFare: false,
                dialogCreateRoom: false,
                dialogCreateHouse: false,
                dialogEditRoom: false,
                dialogEditFare: false,
                dialogEditHouse: false,
                dialogDelete: false,
                panel: [0],
                deleteFormData: {},
                popout: true,
                isLoading: false,
                tab: null,
                settings: [
                    "Hotel Room Setting",
                    "Package Setting",
                    "Hotel Service Setting",
                    "Homestay House Settings",
                    "Homestay Special Dish Setting"
                ],
                //columnsRoom: [
                //    {
                //        label: "Room Number",
                //        field: "roomNumber"
                //    },
                //    {
                //        label: "Category",
                //        field: "roomCategory"
                //    },
                //    {
                //        label: "Type",
                //        field: "roomType"
                //    },
                //    {
                //        label: "Fare",
                //        field: "fare"
                //    },
                //    {
                //        label: "Actions",
                //        field: "actions",
                //        sortable: false
                //    }
                //],
                //columnsFare: [
                //    {
                //        label: "Room Type",
                //        field: "roomType"
                //    },
                //    {
                //        label: "Room Category",
                //        field: "roomCategory"
                //    },
                //    {
                //        label: "Fare",
                //        field: "fare"
                //    },
                //    {
                //        label: "Actions",
                //        field: "actions",
                //        sortable: false
                //    }
                //],
                //columnsHouse: [
                //    {
                //        label: "S No.",
                //        field: "countIndex"
                //    },

                //    {
                //        label: "House Name",
                //        field: "hotelRoomCategory"
                //    },
                //    {
                //        label: "House Owner Name",
                //        field: "houseOwner"
                //    },
                //    {
                //        label: "House no.",
                //        field: "houseNumber"
                //    },
                //    {
                //        label: "Total Capacity",
                //        field: "totalRoom"
                //    },
                //    {
                //        label: "Image",
                //        field: "image"
                //    },
                //    {
                //        label: "Actions",
                //        field: "actions",
                //        sortable: false
                //    }
                //],
                houseRows: [],
                items: [
                    {
                        text: "dashboard",
                        disabled: false,
                        to: "/HomeStayDashboard",
                        exact: true,
                    },
                    {
                        text: "settings",
                        disabled: true,
                    },
                ],
                roomRows: [],
                fareRows: [],
                totalRecords: 0
            };
        },

        mounted() {
        },
        destroyed() {
            console.log(this); // There's practically nothing here!
        },
        methods: {
            onResponseFare(data) {
                console.log(data);
                if (data.status) {
                    this.dialogCreateFare = false;
                    this.dialogEditFare = false;
                    this.callbackResponse.message = data.message;
                    this.snackbar = true;
                    this.loadItemsFare();
                }
            },
            onResponseRoom(data) {
                if (data.status) {
                    this.dialogCreateRoom = false;
                    this.dialogEditRoom = false;

                    this.callbackResponse.message = data.message;
                    this.snackbar = true;
                    this.loadItemsRoom();
                }
            },
            onResponseHouse(data) {
                if (data.status) {
                    this.dialogCreateHouse = false;
                    this.dialogEditHouse = false;

                    this.callbackResponse.message = data.message;
                    this.snackbar = true;
                    this.loadItemsHouse();
                }
            },
            onResponseDelete(data) {
                if (data) {
                    this.dialogDelete = false;
                    this.snackbar = true;
                    this.callbackResponse.message = "Data Deleted Successfully.";
                    this.loadItemsRoom();
                    this.loadItemsFare();
                    this.loadItemsHouse();
                } else {
                    this.dialogDelete = false;
                }
            },
            editItemRoom(props) {
                this.editedDataRoom = props;
                this.dialogEditRoom = true;
                // this.dialogEditFare = true;
            },

            editItemHouse(props) {
                this.editedDataHouse = props;
                this.dialogEditHouse = true;
                // this.dialogEditFare = true;
            },
            editItemFare(props) {
                this.editedDataFare = props;
                this.dialogEditFare = true;
                // this.dialogEditFare = true;
            },

            deleteItemRoom(props) {
                console.log(props);
                this.dialogDelete = true;
                this.deleteFormData.url = "Hotel/DeleteHotelRoomAsync/" + props.roomID;
                this.loadItemsRoom();
            },
            deleteItemFare(props) {
                this.dialogDelete = true;
                this.deleteFormData.url =
                    "Hotel/DeleteHotelRoomFareAsync/" + props.fareID;
            },
            deleteItemHouse(props) {
                this.dialogDelete = true;
                this.deleteFormData.url =
                    "Hotel/DeleteHotelRoomFareAsync/" + props.HomestayHouseID;
            },

            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedData = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300);
            }
        }
    };
</script>

<style lang="scss" scoped>
    .v-item-group {
        background: #d2e3ff73;
    }

    .v-expansion-panel-header {
        background: #d2e3ff73;
    }

    button {
        z-index: 1;
    }
</style>
<style lang="scss">
    .w-full {
        width: 100% !important
    }
</style>